



























































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import Table from '@/components/Table/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import TipModal from '@/views/common/tip-modal.vue'
import { Select, Option } from 'element-ui'
Vue.use(Select)
Vue.use(Option)

@Component({
  components: {
    Table,
    Pagination,
    TipModal
  }
})
export default class Role extends Vue {
  name = ''
  listLoading = false
  tableHeader = [
    {
      key: 'name',
      name: '角色名称',
      width: '20%'
    },
    {
      key: 'remark',
      name: '备注/描述',
      width: '20%',
      remark: true
    },
    {
      key: 'creator',
      name: '创建者',
      width: '20%'
    },
    {
      key: 'createdTime',
      name: '添加日期',
      width: '20%'
    },
    {
      key: 'opt',
      name: '操作',
      width: '',
      isSpecial: true
    }
  ]
  roleId = ''
  tableData: any = []
  layout = 'total, sizes, prev, pager, next, jumper'
  total = 0
  listQuery = {
    page: 1,
    limit: 15
  }
  isOpenModal = false

  created() {
    this.getRoleList()
  }

  searchClick() {
    this.listQuery = {
      page: 1,
      limit: 15
    }
    this.getRoleList()
  }

  // 获取角色列表
  getRoleList() {
    this.listLoading = true
    this.$api.people
      .getRoleLists({
        name: this.name,
        current: this.listQuery.page,
        size: this.listQuery.limit
      })
      .then((res: any) => {
        const data = res.data
        this.listLoading = false
        if (data.success) {
          this.tableData = data.data
          this.total = data.total
        }
      })
      .catch(() => {
        this.listLoading = false
      })
  }
  // 删除角色
  deleteRole() {
    this.listLoading = true
    this.$api.people
      .deleteRole(this.roleId)
      .then((res: any) => {
        this.listLoading = false
        if (res.data.success) {
          this.closeDialog()
          this.$message.success(res.data.msg)
          this.getRoleList()
        }
      })
      .catch(() => {
        this.listLoading = false
      })
  }

  // 关闭对话框
  closeDialog() {
    this.isOpenModal = false
  }

  toDetail(item?: any) {
    if (item && item.id) {
      this.$router.push('/people/role-edit?id=' + item.id)
    } else {
      this.$router.push('/people/role-edit')
    }
  }
}
